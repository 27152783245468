<!--
 * @Descripttion: 公司详情
 * @version: 1.0
 * @Company: ZYZN
 * @Author: Niklaus
 * @Date: 2021-01-05 14:54:55
-->
<template>
	<div class="bg vh">
		<van-nav-bar title="公司信息" left-arrow @click-left="$router.push('/mine')" />

		<div class="flex-between tt">
			<h3>公司LOGO</h3>
			<div class="flex">
				<img :src="editOrg.orgPic? editOrg.orgPic : '/static/img/icon/default-logo.png'" class="wh12">
				<van-icon name="arrow" @click="goFileUpload('0')" />
			</div>
		</div>

		<div class="flex-between tt">
			<h3>公司名称<span style="color:red">*</span></h3>
			<p class="right gray2 fw">{{ editOrg.departName }}
				<van-icon name="arrow" />
			</p>
		</div>

		<div class="flex-between tt">
			<h3>统一社会信用代码<span style="color:red">*</span></h3>
			<p class="right gray2 fw" @click="showEdit = true; type='2',editVal = editOrg.usccCode">{{ editOrg.usccCode }}
				<van-icon name="arrow" />
			</p>
		</div>

		<div class="flex-between tt">
			<h3>联系人</h3>
			<p class="right gray2 fw" @click="showEdit = true; type='3',editVal = editOrg.legalPerson">{{ editOrg.legalPerson }}
				<van-icon name="arrow" />
			</p>
		</div>

		<div class="flex-between tt">
			<h3>联系电话</h3>
			<p class="right gray2 fw" @click="showEdit = true; type='4',editVal = editOrg.mobile">{{ editOrg.mobile }}
				<van-icon name="arrow" />
			</p>
		</div>

		<div class="flex-between tt">
			<h3>营业执照（副本）</h3>
			<div class="flex" @click="goFileUpload('1')">
				<p class="gray2" v-show="editOrg.busiPic">已上传</p>
				<p class="gray2" v-show="editOrg.busiPic==''">未上传</p>
				<van-icon name="arrow" />
			</div>
		</div>

		<div class="flex-between tt">
			<h3>法定代表人身份证</h3>
			<div class="flex" @click="goFileUpload('2')">
				<p class="gray2" v-show="editOrg.idPicBack && editOrg.idPicFront">已上传</p>
				<p class="gray2" v-show="!(editOrg.idPicBack && editOrg.idPicFront)">未上传</p>
				<van-icon name="arrow" />
			</div>
		</div>

		<div class="fixed-bottom pad02" style="background-color:white">
			<button class="btn w-100 pad02" @click="onSave">保存修改</button>
		</div>

		<van-popup v-model="showEdit" position="bottom" style="width:100%" round>
			<div class="pad mar-t">
				<input type="text" class="w-100" v-model="editVal" :maxlength="type =='2' ? '18' : type=='4'? '11' : '100'"
				 placeholder="请输入" style="border-bottom:1px solid #dfe6e9">
				<div class="mar-t flex-between">
					<button class="btn div4" @click="saveItem">确定</button>
					<button class="btn div4" @click="showEdit = false">取消</button>
				</div>
			</div>
		</van-popup>

	</div>
</template>

<script>
	import {
		Cell,
		CellGroup,
		Icon,
		Popup
	} from 'vant'
	export default {

		data() {
			return {
				org: {
					id: '',
					departName: '',
					legalPerson: '',
					mobile: '',
					orgPic: '',
					usccCode: '',
					busiPic: '',
					idPicFront: '',
					idPicBack: '',
				},
				showEdit: false,
				type: '',
				editVal: '',
				mobileReg: /^1[3|4|5|7|8|9]\d{9}$/, //手机号正则表达式
				ucReg: /^([0-9A-HJ-NPQRTUWXY]{2}\d{6}[0-9A-HJ-NPQRTUWXY]{10})$/, //统一社会信用代码正则表达式
				editOrg: {},
				workweixin: false,				//企业微信
				openUserid:	"",
			}
		},

		components: {
			[Icon.name]: Icon,
			[Cell.name]: Cell,
			[Popup.name]: Popup,
			[CellGroup.name]: CellGroup,
		},
		beforeRouteEnter(to, from, next) {
			next(vm => {
				/** 若从文件上传页面跳转，则从缓存中获取修改数据 */
				if (from.name == "file") {
					vm.editOrg = vm.$ls.get("editOrgTemp")
				} else {
					vm.$ls.remove("editOrgTemp")
					vm.editOrg = { ...vm.$ls.get("edOrgInfo")
					}
				}
				next()
			})

		},
		created(){
			//企微微信标志
			this.workweixin = this.$ls.get("expert_workweixin_flag")&&"1"==this.$ls.get("expert_workweixin_flag")
			if(this.workweixin){
				this.openUserid = this.$ls.get("expert_workweixin_user").open_userid
			}
			//
		},
		methods: {
			goFileUpload(index) {
				this.$ls.set("editOrgTemp", this.editOrg)
				this.$router.push('/file/' + index)
			},
			saveItem() {
				if (this.type == '1')
					this.editOrg.departName = this.editVal
				else if (this.type == '2') {
					let tt = new RegExp(this.ucReg)
					if (!tt.test(this.editVal)) {
						this.$toast({
							message: '统一社会信用代码\n格式不正确',
							type: 'fail',
							className: 'div5'
						})
						return
					}
					this.editOrg.usccCode = this.editVal
				} else if (this.type == '3')
					this.editOrg.legalPerson = this.editVal
				else if (this.type == '4') {
					//   let tt = new RegExp(this.mobileReg)
					//   if(!tt.test(this.editVal)){
					//       this.$toast({
					//           message: '手机号码\n格式不正确',
					//           type: 'fail',
					//       })
					//       return
					//   }
					this.editOrg.mobile = this.editVal
				}

				this.editVal = ''
				this.showEdit = false
			},
			//保存机构信息
			onSave() {
				if (this.editOrg.departName.length > 100) {
					this.$toast("机构名称过长，请修改！")
					return
				}
				/** 若缓存中无数据说明本次修改未操作图片 */
				let tempOrg = this.$ls.get("editOrgTemp") || this.editOrg;

				this.$ajax({
					method: "put",
					url: "/gateway/ed/wx/mineController/updateExternal",
					headers: {
						"X-Access-Token": this.$ls.get("token"),
					},
					params: {
						openid: (this.workweixin?this.openUserid:this.$ls.get("openid"))
					},
					data: tempOrg
				}).then((res) => {
					if (res.data.success) {
						this.$toast.success("修改成功")
						/** 更新机构缓存数据 */
						let tt = this.$ls.get('edCurrentOrg')
						tt.departName = tempOrg.departName
						tt.orgCode = tempOrg.usccCode
						tt.mobile = tempOrg.mobile
						tt.legalPerson = tempOrg.legalPerson
						this.$ls.set('edCurrentOrg', tt)
					} else {
						this.$toast.fail(res.data.message)
					}
				});
			}
		}
	}
</script>

<style scoped>
	.tt {
		background-color: white;
		padding: 0.2rem 0.4rem;
		border-bottom: 2px solid #eeecec;
		min-height: 1.6rem;
	}

	.tt h3 {
		font-size: 0.45rem;
		width: 4rem;
		flex-shrink: 0;
	}

	.tt input,
	.tt>p {
		width: calc(100% - 4rem);
		word-break: break-all;
	}

	.tt textarea {
		height: auto;
		overflow-x: visible;
		overflow-y: visible;
	}

	.fw {
		font-size: 0.42rem
	}

	.van-cell {
		display: flex;
	}

	.div4 {
		width: 40%;
		bottom: 1rem;
		display: inline-block;
	}

	.div4:last-child {
		right: 0.5rem;
		background-color: rgb(170, 160, 160);
	}
</style>
